<template>
  <b-card :title="`${$t('General.Update')} ${$t('Item')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('General.ArabicName')"
                label-for="arabicName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.ArabicName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_ar"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.ArabicName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.EnglishName')"
                label-for="englishName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.EnglishName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_en"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.EnglishName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Item Group')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Item Group')"
                  label-for="itemGroup"
                >
                  <v-select
                    v-model="item_group_id"
                    :state="item_group_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="item_group_option"
                    :placeholder="$t('Item Group')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Item Type')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Item Type')"
                  label-for="itemType"
                >
                  <v-select
                    v-model="item_type_id"
                    :state="item_type_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="item_type_option"
                    :placeholder="$t('Item Type')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Brand')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Brand')"
                  label-for="brand"
                >
                  <v-select
                    v-model="brand_id"
                    :state="brand_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="brand_option"
                    :placeholder="$t('Brand')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                :name="$t('Item Structure')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Item Structure')"
                  label-for="itemStructure"
                >
                  <v-select
                    v-model="item_structure_id"
                    :state="item_structure_id === null ? false : true"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    :options="item_structure_option"
                    :placeholder="$t('Item Structure')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('WarehouseInfo.Max Quantity')"
                label-for="max"
              >
                <b-form-input
                  v-model="max"
                  type="number"
                  :placeholder="$t('WarehouseInfo.Max Quantity')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('WarehouseInfo.Min Quantity')"
                label-for="min"
              >
                <b-form-input
                  v-model="min"
                  type="number"
                  :placeholder="$t('WarehouseInfo.Min Quantity')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('WarehouseInfo.Time Response (Days)')"
                label-for="timeResponse"
              >
                <b-form-input
                  v-model="time_response"
                  type="number"
                  :placeholder="$t('WarehouseInfo.Time Response (Days)')"
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                :label="$t('General.Attachment')"
                label-for="attachment"
              >
                <b-form-file
                  v-model="file"
                  :placeholder="$t('General.Choose a file or drop it here')"
                  :drop-placeholder="$t('General.Drop file here')"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.Active')"
                label-for="active"
              >
                <b-form-checkbox
                  v-model="active"
                  class="custom-control-primary"
                >
                  {{ $t('General.Active') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.Expiration')"
                label-for="expiration"
              >
                <b-form-checkbox
                  v-model="is_expiration"
                  class="custom-control-primary"
                >
                  {{ $t('General.Expiration') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('General.Description')"
                label-for="description"
              >
                <b-form-textarea
                  v-model="description"
                  :placeholder="$t('General.Description')"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="6" />
            <b-col cols="12">
              <h4 class="p-1 text-left">
                {{ `${$t('General.Add')} ${$t('Item')} ${$t('Details')}` }}
              </h4>
            </b-col>
            <!-- Start Item Array -->
            <b-form
              v-if="item_structure_id.id === 1"
              ref="formGrid"
            >
              <b-row class="p-1">
                <b-col md="6">
                  <b-form-group
                    :label="$t('WarehouseInfo.Barcode')"
                    label-for="barcode"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('WarehouseInfo.Barcode')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="barcode"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('WarehouseInfo.Barcode')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Measurement Unit')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Measurement Unit')"
                      label-for="measurementUnit"
                    >
                      <v-select
                        v-model="measurement_unit_id"
                        :state="measurement_unit_id === null ? false : true"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                        :options="measurement_unit_option"
                        :placeholder="$t('Measurement Unit')"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false:null"
                      >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('WarehouseInfo.Sale Price')"
                    label-for="salePrice"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('WarehouseInfo.Sale Price')"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <b-form-input
                        v-model.number="sale_price"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('WarehouseInfo.Sale Price')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('WarehouseInfo.Purchase Price')"
                    label-for="purchasePrice"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('WarehouseInfo.Purchase Price')"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <b-form-input
                        v-model.number="purchase_price"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('WarehouseInfo.Purchase Price')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('WarehouseInfo.Pieces')"
                    label-for="pieces"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('WarehouseInfo.Pieces')"
                      rules="required|integer"
                    >
                      <b-form-input
                        v-model.number="pieces"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('WarehouseInfo.Pieces')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="1" />
                <b-col cols="2 text-center p-1 mt-1">
                  <b-button
                    variant="info"
                    type="button"
                    block
                    @click.prevent="addGrid"
                  >
                    {{ $t('General.Add') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-col
              v-if="item_structure_id.id === 1"
              cols="3"
            />
            <b-col
              v-if="item_structure_id.id === 1"
              cols="12"
            >
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                responsive
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          @click="updateGrid(props.row.id)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Edit') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeGrid(props.row.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Delete') }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        {{ $t('Showing 1 to') }}
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10','50','100']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> {{ $t('of') }} {{ props.total }} {{ $t('General.entries') }} </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <!-- End Item Array -->
            <!-- Start Item Collection -->
            <b-form
              v-if="item_structure_id.id === 2"
              ref="formCollection"
            >
              <b-row class="p-1">
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    :name="`${$t('Item')} ${$t('General.Name')}`"
                    rules="required"
                  >
                    <b-form-group
                      :label="`${$t('Item')} ${$t('General.Name')}`"
                      label-for="itemName"
                    >
                      <v-select
                        v-model="item_id"
                        :state="item_id === null ? false : true"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                        :options="item_option"
                        :placeholder="`${$t('Item')} ${$t('General.Name')}`"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false:null"
                      >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('WarehouseInfo.Sale Price')"
                    label-for="salePrice"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('WarehouseInfo.Sale Price')"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <b-form-input
                        v-model.number="sale_price"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('WarehouseInfo.Sale Price')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('WarehouseInfo.Purchase Price')"
                    label-for="purchasePrice"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('WarehouseInfo.Purchase Price')"
                      rules="required|regex:^([0-9]+)$"
                    >
                      <b-form-input
                        v-model.number="purchase_price"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('WarehouseInfo.Purchase Price')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4" />
                <b-col cols="4 text-center p-1 mt-1">
                  <b-button
                    variant="info"
                    type="button"
                    block
                    @click.prevent="addCollection"
                  >
                    {{ $t('WarehouseInfo.Purchase Price') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-col
              v-if="item_structure_id.id === 2"
              cols="4"
            />
            <b-col
              v-if="item_structure_id.id === 2"
              cols="12"
            >
              <vue-good-table
                :columns="columns_collection"
                :rows="rows_collection"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
                responsive
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          @click="updateCollection(props.row.id)"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Edit') }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeCollection(props.row.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>{{ $t('General.Delete') }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        {{ $t('Showing 1 to') }}
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10','50','100']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> {{ $t('of') }} {{ props.total }} {{ $t('General.entries') }} </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <!-- End Item Collection -->
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="button"
                block
                @click="validationForm"
              >
                {{ $t('General.Save') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BFormCheckbox, BFormTextarea,
  BPagination, BFormSelect, BDropdown, BDropdownItem, BFormFile, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import item from '@/service/warehouse/item'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import itemGroup from '@/service/warehouse/item-group'
import itemType from '@/service/warehouse/item-type'
import measurementUnit from '@/service/warehouse/measurement-unit'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import brand from '@/service/warehouse/brand'
import itemStructure from '@/service/warehouse/item-structure'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BFormInvalidFeedback,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      name_ar: '',
      name_en: '',
      required,
      integer,
      description: '',
      item_group_id: '',
      item_group_option: [],
      item_type_id: '',
      item_type_option: [],
      brand_id: '',
      brand_option: [],
      item_structure_id: '',
      item_structure_option: [],
      item_id: '',
      item_option: [],
      max: '',
      min: '',
      time_response: '',
      measurement_unit_id: '',
      measurement_unit_option: [],
      barcode: '',
      sale_price: '',
      purchase_price: '',
      pieces: '',
      active: true,
      file: [],
      is_expiration: false,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: this.$t('WarehouseInfo.Barcode'),
          field: 'barcode',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('WarehouseInfo.Barcode')}`,
          },
        },
        {
          label: this.$t('Measurement Unit'),
          field: 'measurement_unit.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Measurement Unit')}`,
          },
        },
        {
          label: this.$t('WarehouseInfo.Sale Price'),
          field: 'sale_price',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('WarehouseInfo.Sale Price')}`,
          },
        },
        {
          label: this.$t('WarehouseInfo.Purchase Price'),
          field: 'purchase_price',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('WarehouseInfo.Purchase Price')}`,
          },
        },
        {
          label: this.$t('WarehouseInfo.Pieces'),
          field: 'pieces',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('WarehouseInfo.Pieces')}`,
          },
        },
        {
          label: this.$t('General.Action'),
          field: 'action',
        },
      ],
      rows: [],
      columns_collection: [
        {
          label: `${this.$t('Item')} ${this.$t('General.Name')}`,
          field: 'item.name_en',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('Item')} ${this.$t('General.Name')}`,
          },
        },
        {
          label: this.$t('WarehouseInfo.Sale Price'),
          field: 'sale_price',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('WarehouseInfo.Sale Price')}`,
          },
        },
        {
          label: this.$t('WarehouseInfo.Purchase Price'),
          field: 'purchase_price',
          filterOptions: {
            enabled: true,
            placeholder: `${this.$t('General.Search')} ${this.$t('WarehouseInfo.Purchase Price')}`,
          },
        },
        {
          label: this.$t('General.Action'),
          field: 'action',
        },
      ],
      rows_collection: [],
      searchTerm: '',
      count: 0,
      count_col: 0,
      isUpdate: false,
      vauleUpdate: '',
      locale: this.$i18n.locale,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.layout.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    await this.getApiSelect()
    this.showOverLay = true
    await item.oneAxios(this.id).then(response => {
      this.name_ar = response.data.name_ar
      this.name_en = response.data.name_en
      this.description = response.data.description
      this.max = response.data.max
      this.min = response.data.min
      this.time_response = response.data.time_response
      this.active = response.data.active === 1
      this.is_expiration = response.data.is_expiration === 1
      this.item_group_id = this.item_group_option.find(x => x.id === response.data.item_group_id)
      this.item_type_id = this.item_type_option.find(x => x.id === response.data.item_type_id)
      this.brand_id = this.brand_option.find(x => x.id === response.data.brand_id)
      this.item_structure_id = this.item_structure_option.find(x => x.id === response.data.item_structure_id)
      response.data.item_details.forEach(detail => {
        // eslint-disable-next-line no-plusplus
        this.count++
        const addData = {
          id: this.count,
          barcode: detail.barcode,
          measurement_unit: this.measurement_unit_option.find(x => x.id === detail.measurement_unit_id),
          measurement_unit_id: detail.measurement_unit_id,
          sale_price: detail.sale_price,
          purchase_price: detail.purchase_price,
          pieces: detail.pieces,
        }
        this.rows.push(addData)
      })
      this.showError = false
    }).catch(error => {
      this.apiErrors = error
      this.showError = true
    }).finally(() => { this.showOverLay = false })
  },
  methods: {
    addGrid() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.isUpdate) {
            const addData = {
              id: this.vauleUpdate,
              barcode: this.barcode,
              measurement_unit: this.measurement_unit_id,
              measurement_unit_id: this.measurement_unit_id.id,
              sale_price: this.sale_price,
              purchase_price: this.purchase_price,
              pieces: this.pieces,
            }
            const foundIndex = this.rows.findIndex(x => x.id === this.vauleUpdate)
            this.rows[foundIndex] = addData
            this.rows.push({})
            this.rows.pop()
            this.vauleUpdate = ''
            this.isUpdate = false
          } else {
            // eslint-disable-next-line no-plusplus
            this.count++
            const addData = {
              id: this.count,
              barcode: this.barcode,
              measurement_unit: this.measurement_unit_id,
              measurement_unit_id: this.measurement_unit_id.id,
              sale_price: this.sale_price,
              purchase_price: this.purchase_price,
              pieces: this.pieces,
            }
            this.clearGrid()
            this.rows.push(addData)
          }
          this.$refs.formGrid.reset()
          // this.postApiProduct()
        }
      })
    },
    addCollection() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.isUpdate) {
            const addData = {
              id: this.vauleUpdate,
              item: this.item_id,
              item_id: this.item_id.id,
              sale_price: this.sale_price,
              purchase_price: this.purchase_price,
            }
            const foundIndex = this.rows_collection.findIndex(x => x.id === this.vauleUpdate)
            this.rows_collection[foundIndex] = addData
            this.rows_collection.push({})
            this.rows_collection.pop()
            this.vauleUpdate = ''
            this.isUpdate = false
          } else {
            // eslint-disable-next-line no-plusplus
            this.count_col++
            const addData = {
              id: this.count_col,
              item: this.item_id,
              item_id: this.item_id.id,
              sale_price: this.sale_price,
              purchase_price: this.purchase_price,
            }
            this.rows_collection.push(addData)
          }
          this.clearCollection()
          this.$refs.formCollection.reset()
          // this.postApiProduct()
        }
      })
    },
    async validationForm() {
      await this.fullGrid()
      await this.fullCollection()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.putApi()
        }
        this.switchLoc()
      })
    },
    async putApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      const formData = new FormData()
      formData.append('name_ar', this.name_ar)
      formData.append('name_en', this.name_en)
      formData.append('active', this.active ? 1 : 0)
      formData.append('item_type_id', this.item_type_id.id)
      formData.append('item_group_id', this.item_group_id.id)
      formData.append('brand_id', this.brand_id.id)
      formData.append('item_structure_id', this.item_structure_id.id)
      formData.append('max', this.max)
      formData.append('min', this.min)
      formData.append('time_response', this.time_response)
      formData.append('description', this.description)
      formData.append('is_expiration', this.is_expiration ? 1 : 0)
      // formData.append('current_quantity', 0)
      formData.append('user_id', user.id)
      if (this.item_structure_id.id === 1) {
        formData.append('itemDetails', JSON.stringify(this.rows))
      } else {
        formData.append('itemDetails', JSON.stringify(this.rows_collection))
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0, l = this.file.length; i < l; i++) {
        formData.append(`file[${i}]`, this.file[i])
      }
      // const resultData = await item.postAxios({
      //   name_ar: this.name_ar,
      //   name_en: this.name_en,
      //   active: this.active,
      //   is_expiration: this.is_expiration,
      //   description: this.description,
      //   item_type_id: this.item_type_id.id,
      //   item_group_id: this.item_group_id.id,
      //   current_quantity: 0,
      //   user_id: user.id,
      //   itemDetails: this.rows,
      // }).catch(error => {
      //   this.apiErrors = error
      //   this.showError = true
      //   this.showOverLay = false
      //   return error
      // })
      await item.putAxios(this.id, formData).then(() => {
        // this.$refs.formName.reset()
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        // this.clearForm()
        this.showOverLay = false
      })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    clearGrid() {
      this.measurement_unit_id = ''
      this.barcode = ''
      this.sale_price = ''
      this.purchase_price = ''
      this.pieces = ''
      this.$refs.simpleRules.reset()
    },
    fullGrid() {
      this.measurement_unit_id = '.'
      this.barcode = '0'
      this.sale_price = '0'
      this.purchase_price = '0'
      this.pieces = '0'
      this.$refs.simpleRules.reset()
    },
    clearCollection() {
      this.item_id = ''
      this.sale_price = ''
      this.purchase_price = ''
      this.$refs.simpleRules.reset()
    },
    fullCollection() {
      this.item_id = '.'
      this.sale_price = '0'
      this.purchase_price = '0'
      this.$refs.simpleRules.reset()
    },
    async getApiSelect() {
      this.showOverLay = true
      await itemGroup.getAxios().then(response => {
        this.item_group_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await itemType.getAxios().then(response => {
        this.item_type_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await brand.getAxios().then(response => {
        this.brand_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await itemStructure.getAxios().then(response => {
        this.item_structure_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await measurementUnit.getAxios().then(response => {
        this.measurement_unit_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await item.getAxios().then(response => {
        this.item_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
    updateGrid(id) {
      const updateValue = this.rows.find(x => x.id === id)
      this.clearGrid()
      if (updateValue) {
        this.vauleUpdate = id
        this.barcode = updateValue.barcode
        this.measurement_unit_id = updateValue.measurement_unit
        this.sale_price = updateValue.sale_price
        this.purchase_price = updateValue.purchase_price
        this.pieces = updateValue.pieces
        this.isUpdate = true
      }
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    removeGrid(id) {
      this.rows.splice(this.rows.findIndex(a => a.id === id), 1)
    },
    updateCollection(id) {
      const updateValue = this.rows_collection.find(x => x.id === id)
      this.clearCollection()
      if (updateValue) {
        this.vauleUpdate = id
        this.item_id = updateValue.item
        this.sale_price = updateValue.sale_price
        this.purchase_price = updateValue.purchase_price
        this.isUpdate = true
      }
    },
    removeCollection(id) {
      this.rows.splice(this.rows_collection.findIndex(a => a.id === id), 1)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
